<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-cog"></i>
    <h3 class="box-title text-right">Service Level Configuration</h3>
  </div>
  <div class="box-body">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group has-error">
          <label class="control-label text-left">Service Level Name</label>
          <input id="name" class="is-invalid form-control" type="text">
          <div class="invalid-feedback">
            <span>Service Level Name is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group has-error">
          <label class="control-label text-left">Minutes (Lower Means More Urgent)</label>
          <input id="minutes" class="is-invalid form-control" type="number">
          <div class="invalid-feedback">
            <span>Minutes is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label text-left">Property Type</label>
          <select id="PropertyType" class="form-control">
            <option value="">--Select a property type--</option>
            <option>Contact</option>
            <option>Interaction</option>
          </select>
        </div>
      </div>
    </div>
    <!--v-if-->
    <div class="row">
      <div class="col-md-2 mt-3">
        <button id="Create" class="btn btn-primary margin-r-5">Create</button>
        <button id="Cancel" class="btn btn-link">Cancel</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 mt-3 admin-error">
        <!--v-if-->
      </div>
    </div>
  </div>
</div>

   
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import ServicelevelDetail from '../components/ServicelevelDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Servicelevel Settings',
   components: {
    Breadcrumb,
    iServiceSwitch,
    Multiselect,
    Toggle,
    Multiselect,
    ServicelevelDetail
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showServicelevelDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showServicelevelDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showServicelevelDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showServicelevelDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
