<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-cog"></i>
    <h3 class="box-title text-right">Service Level Configuration</h3>
  </div>
  <div class="box-body">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group has-error">
          <label class="control-label text-left">Service Level Name</label>
          <input id="name" class="is-invalid form-control" type="text">
          <div class="invalid-feedback">
            <span>Service Level Name is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group has-error">
          <label class="control-label text-left">Minutes (Lower Means More Urgent)</label>
          <input id="minutes" class="is-invalid form-control" type="number">
          <div class="invalid-feedback">
            <span>Minutes is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label text-left">Property Type</label>
          <select id="PropertyType" class="form-control">
            <option value="">--Select a property type--</option>
            <option>Contact</option>
            <option>Interaction</option>
          </select>
        </div>
      </div>
    </div>
    <!--v-if-->
    <div class="row">
      <div class="col-md-2 mt-3">
        <button id="Create" class="btn btn-primary margin-r-5">Save</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 mt-3 admin-error">
        <!--v-if-->
      </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="nested-panel-heading skill-heading">
                <h3 class="nested-title">Topics That Use This Service Level</h3>
            </div>
            <div class="skill-list">
                <div class="list-headings">Sales Segment </div>
                <div class="segment-item">
                <span class="skill-item">
                    <a href="">All Topics</a>
                    <a href="">Sales Prospecting</a>
                    <span class="fa fa-close text-red"></span>
                </span>
                </div>
                <div class="segment-item">
                <span class="skill-item">
                    <a href="">All Topics</a>
                    <a href="">Follow Ups</a>
                    <span class="fa fa-close text-red"></span>
                </span>
                </div>
                <div class="list-headings">Human Resource Segment</div>
                <div class="segment-item">
                <span class="skill-item">
                    <a href="">All Topics</a>
                    <a href="">_Spam</a>
                    <span class="fa fa-close text-red"></span>
                </span>
                </div>
            </div>
        </div>
       
        <div class="col-md-4">
            <div class="nested-panel-heading skill-heading">
                <h3 class="nested-title">Segment Defaults That Use This Service Level </h3>
            </div>
            <div class="skill-list">
                <span class="skill-item">
                <a href="">Sales Segment</a>
                <span class="fa fa-close text-red"></span>
                </span>
                <span class="skill-item">
                <a href="">Customer Service Segment</a>
                <span class="fa fa-close text-red"></span>
                </span>
                <span class="skill-item">
                <a href="">Human Resource Segment</a>
                <span class="fa fa-close text-red"></span>
                </span>
            </div>
        </div>
       </div>
  </div>
</div>
</template>