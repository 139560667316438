
<template>
  <div class="iservice-switch">
    <label class="switch" v-bind:class="{ 'disabled': disabled }">
    <input type="checkbox" v-bind:checked="modelValue" v-bind:disabled="disabled" @input="Input" iservice-switch />
    <i></i> <slot></slot>
    </label>
  </div>
</template>

<script>
  export default{

    name: 'iservice-switch',
    template: '#iservice-switch',
    props: ['modelValue', 'disabled'],
    setup(props, context) {
        function Input(e) {
            context.emit('update:modelValue', e.target.checked);
        }
    return { Input };
    }
  }
</script>